<template>
  <div>
    <!-- faq search section -->
    <section id="faq-search-filter">
      <b-card
        no-body
        class="faq-search"
        :style="{
          backgroundImage: `url(${require('@/assets/images/banner/banner.png')})`,
        }"
      >
        <b-card-body class="text-center">
          <h2
            class="text-primary"
            v-if="language == 'en' && $store.state.auth.token == null"
          >
            Website Terms Aequitex
          </h2>
          <h2
            class="text-primary"
            v-else-if="language == 'de' && $store.state.auth.auth == null"
          >
            Website Nutzungsbestimmungen Aequitex
          </h2>

          <!-- AG VERSION 1.0 – NOVEMBER 22, 2021 -->
          <!-- form -->

          <!-- form -->
        </b-card-body>
      </b-card>
    </section>
    <!--/ faq search section -->

    <!-- frequently asked questions tabs pills -->
    <section id="faq-tabs">
      <b-tabs
        vertical
        content-class="col-12 col-md-8 col-lg-8"
        pills
        nav-wrapper-class="faq-navigation col-md-4 col-lg-3 col-12"
        nav-class="nav-left"
      >
        <div>
          <b-card class="col-md-9"
            ><br /><br />
            <div class="col-md-12">
              <h4 class="row justify-content-between">
                <div v-html="language == 'en' ? '1 SCOPE' : 'UMFANG'"></div>

                <div class="row">
                  <div class="col-md-12 pr-0" align="end">
                    <b-dropdown
                      text="Dark"
                      variant="outline-dark"
                      id="dropdown-grouped"
                      class="dropdown-language no-border-button"
                      right
                    >
                      <template #button-content>
                        <b-img
                          :src="currentLocale.img"
                          height="14px"
                          width="22px"
                          :alt="currentLocale.locale"
                        />
                        <span class="ml-50 text-body">{{
                          $t(currentLocale.name)
                        }}</span>
                      </template>
                      <b-dropdown-item
                        v-for="localeObj in locales"
                        :key="localeObj.locale"
                        @click="$i18n.locale = localeObj.locale"
                      >
                        <b-img
                          :src="localeObj.img"
                          height="14px"
                          width="22px"
                          :alt="localeObj.locale"
                        />
                        <span class="ml-50">{{ $t(localeObj.name) }}</span>
                      </b-dropdown-item>
                    </b-dropdown>
                  </div>
                </div>
              </h4>
            </div>

            <LightGTCde v-if="language == 'en'"></LightGTCde>
            <LightGTC v-else-if="language == 'de'"></LightGTC>
          </b-card>
        </div>
        <!-- payment tab -->

        <!--/ payment tab -->

        <!-- sitting lady image -->
        <template #tabs-end>
          <!-- <b-img
            fluid
            :src="require('@/assets/images/illustration/faq-illustrations.svg')"
            class="d-none d-md-block mt-auto"
          /> -->
        </template>
        <!--/ sitting lady image -->
      </b-tabs>
    </section>
  </div>
</template>

<script>
import AppCollapse from "@core/components/app-collapse/AppCollapse.vue";
import AppCollapseItem from "@core/components/app-collapse/AppCollapseItem.vue";
import i18n from "@/libs/i18n";
import En from "./TermsEn.vue";
import De from "./TermsDe.vue";
import LightGTC from "./LightGtc.vue";
import LightGTCde from "./LightGtc_de.vue";

export default {
  components: {
    AppCollapse,
    AppCollapseItem,
    En,
    De,
    LightGTC,
    LightGTCde,
  },
  data() {
    return {
      language: "en",
      locales: [
        {
          locale: "en",
          img: "/images/flags/en.png",
          name: "english",
        },

        {
          locale: "de",
          img: "/images/flags/german.png",
          name: "german",
        },
      ],
      options: {
        title: this.$t("faq_title"),
        subtitle: this.$t("lets_answer_some_questions"),
        icon: "creditCardIcon",
        qandA: [
          {
            question: this.$t("first_faq_question"),
            ans: this.$t("first_faq_answer"),
          },
          {
            question: this.$t("second_faq_question"),
            ans: this.$t("second_faq_answer"),
          },
          {
            question: this.$t("third_faq_question"),
            ans: this.$t("third_faq_answer"),
          },
          {
            question: this.$t("fourth_faq_question"),
            ans: this.$t("fourth_faq_answer"),
          },
        ],
      },
      faqSearchQuery: "",
      faqData: {},
      faq: {
        icon: "",
        title: "testt",
        subtitle: "anss",
      },
    };
  },
  watch: {
    faqSearchQuery: {
      immediate: true,
      handler() {},
    },
  },
  computed: {
    currentLocale() {
      let obj = null;
      if (this.$i18n.locale) {
        if (this.$i18n.locale == "en") {
          obj = {
            locale: "en",
            img: "/images/flags/en.png",
            name: "english",
          };
        } else {
          obj = {
            locale: "de",
            img: "/images/flags/german.png",
            name: "german",
          };
        }
      } else {
        obj = {
          locale: "en",
          img: "/images/flags/en.png",
          name: "english",
        };
      }
      this.language = obj.locale;
      this.$store.commit("app/UPDATE_LANGUAGE", obj.locale);
      return obj;
    },
  },
  methods: {
    toggleLanguage(language) {
      this.language = language;
    },
  },
};
</script>
<style>
</style>

<style lang="scss">
@import "@core/scss/vue/pages/page-faq.scss";
</style>
